#classic-layout .sider-header {
    height: 100px;
    background: #003594;
    color: #fff;
}

#classic-layout .sider-header .logo {
    position: relative;
    transition: all .3s;
    overflow: hidden;
    alignment: center;
    vertical-align: middle;
    font-size: 24px;
    font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-align: center;
    line-height: 70px;
}

#classic-layout .sider-header .trigger {
    font-size: 18px;
    /*line-height: 30px;*/
    /*padding: 0 16px;*/
    cursor: pointer;
    transition: color .3s;
    text-align: right;
    padding-right: 10px;
}

#classic-layout .trigger:hover {
    color: #108ee9;
}

#classic-layout .ant-layout-sider-collapsed .logo {
    padding-left: 20px;
}

#classic-layout .logo img {
    display: inline-block;
    vertical-align: middle;

    height: 30px;
    max-width: 170px;
}

#classic-layout .ant-layout-sider {
    min-height: 100vh;
    box-shadow: 1px 0 2px 1px #B6B6B6;
    position: relative;
    z-index: 10;
    background: #FFFFFF;
}

#classic-layout .ant-layout-header {
    padding: 0 12px 0 0;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
    position: relative;
    line-height: inherit;
}

#classic-layout .ant-menu-horizontal {
    border-bottom: 0px;
    height: inherit;
    line-height: 100px;
}

#classic-layout .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: 0px;
}

#classic-layout .ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title {
    font-size: 14px;
}

#classic-layout .ant-menu-dark, .ant-menu-dark .ant-menu-sub {
    /*color: rgba(255, 255, 255, 0.65);*/
    background: #FFFFFF;
}

#classic-layout .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #000c17;
}

.ant-dropdown-menu-item {
    width: 160px;
}

/*#classic-layout .account-menu {*/
/*width: 160px;*/
/*}*/

#classic-layout .account {
    margin: 0 20px;
    background: rgba(255, 255, 255, .85);
    vertical-align: middle;
    height: 100px;
    padding: 20px 0;
}

#classic-layout .avatar {
    margin: 16px 8px 15px 0;
    color: #1890ff;
    background: rgba(255, 255, 255, .85);
    vertical-align: middle;
}

#classic-layout .footer {
    background-color: #FFFFFF;
}

#classic-layout .main-menu {
    height: 100px;
    display: flex;
    width: 100%
}

#classic-layout .account-menu .ant-menu {
    height: 100px;
}

#classic-layout .main-menu a {
    width: 120px;
    height: 100px;
    text-align: center;
    color: #000;
    opacity: 0.6;
    padding-top: 5px;
}

#classic-layout .main-menu .active {
    background: #003594;
    color: #fff;
    opacity: 1;
}

#classic-layout .main-menu a:hover {
    background: #003594;
    color: #fff;
    opacity: 1;
}

#classic-layout .ant-layout-footer {
    padding: 5px 50px;
}
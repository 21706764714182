body {
    margin: 0;
    padding: 0;
    font-family: "Microsoft YaHei", "Arial", "黑体", "宋体", sans-serif;
    font-size: 14px;
    line-height: 1.5;
    background: #ededeb;
    -webkit-font-smoothing: antialiased;
    height: 100vh;
}

a:hover {
    text-decoration: none;
    cursor: pointer;
}

.page-content {
    background-color: #F5F5F5;
    padding: 15px;
    /*max-height: 0px;*/
    overflow-y: auto;
    flex: auto;
    box-sizing: border-box;
    display: block;
    height: 100%;
    width: 100%;
}

.spacing-h {
    margin-right: 10px;
}

.spacing-v {
    margin-top: 10px;
}

/*奇数行*/
.ant-table-tbody > tr:nth-of-type(odd) {
    background: #ffffff;
}

/*偶数行*/
.ant-table-tbody > tr:nth-of-type(even) {
    background: #f9f9f9;
}

/*Ant Design 默认的Form Item 底部间距太大*/
.ant-form-item {
    margin-bottom: auto;
}

/*清空padding的设置*/
.clear-padding {
    padding: 0px;
}

/*Ant Design的模态框padding默认24过大*/
.ant-modal-body {
    padding: 10px;
}

/*垂直居中*/
.vertical-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/*文字不换行，超出部分省略号, 需要为容器加上width限定，span无效*/
.overFlowEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.overFlowEllipsis:hover {
    overflow: visible;
    white-space: normal;
}
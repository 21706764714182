.react-resizable {
    position: relative;
    border: solid 1px #000000;
}

.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
}

/** {*/
/*box-sizing: border-box;*/
/*}*/
/*html, body {*/
/*height: 100%;*/
/*}*/

/*body {*/
/*color: #222;*/
/*font-family: "Helvetica Neue", sans-serif;*/
/*font-weight: 200;*/
/*margin: 0 50px;*/
/*}*/

.react-draggable, .cursor {
    cursor: move;
}

.no-cursor {
    cursor: auto;
}

.cursor-y {
    cursor: ns-resize;
}

.cursor-x {
    cursor: ew-resize;
}

.react-draggable strong {
    background: #ddd;
    border: 1px solid #999;
    border-radius: 3px;
    display: block;
    margin-bottom: 10px;
    padding: 3px 5px;
    text-align: center;
}

.box {
    background: #fff;
    border: 1px solid #999;
    border-radius: 3px;
    width: 180px;
    height: 180px;
    margin: 10px;
    padding: 10px;
    float: left;
}

.no-pointer-events {
    pointer-events: none;
}

.hovered {
    background-color: gray;
}

/*
 * RemWrapper needs to take it's styles from this element,
 * and this element can't have an absolute position after it's kicked in.
 * AFAIK it's not possible to do this directly in the RemWrapper component.
 */
.rem-position-fix {
    position: static !important;
}

.ppt-page-content-container {

    width: 100%;
}

.ppt-page-content {
    background-repeat:no-repeat;
    width: 1124px;
    height: 792px;
    margin: 0 auto;
    background-size:cover;
 border:2px solid grey;
}

.ppt-page-list {
    /*position: fixed;*/
    overflow-y: auto;
    background-color: #e6e9ec;
    width: 255px;
    float: right
}

.ppt-page-list-item {
    padding: 5px;
    height: 140px;
    margin-bottom: 5px;
    width: 250px;
}

.ppt-page-tool-bar {
    font-size: 18px;
    font-weight: 600;
    /*position: fixed;*/
    overflow-y: auto;
    background-color: #e6e9ec;
    width: 80px;
    left: 5px;
    padding: 1px;
}

.ppt-page-tool-bar button {
    width: 78px;
    height: 75px;
    padding: 1px;
}
.select-box {
    border: 2px dotted #348fff;
    /*padding: 1px;  */
}
.content-item-show-penal{
     width:100% ;
     min-height:120px ;
     position: relative;
 }
.content-item-image{
    border:2px rgba(90, 156, 255, 0.45) solid;
    position: absolute;
}
.answer-rectangle-content{
    width:100% ;
    position: absolute;
    top:0;
    left: 0;
    /*height: 120px;*/
    /*background-color: rgba(145, 213, 255, 0.34);*/
}
.answer-rectangle{
    border:1px red dotted;
   /*background-color: red;*/
}
.answer-rectangle-select{
    background-color: rgba(145, 213, 255, 0.34);
    /*background-color: red;*/
}
.content-item-line{
    border-bottom: 1px grey solid;
}
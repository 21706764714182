/*重写bootstrap的panel样式*/
.panel {
    border-radius: 0px;
    border: none;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.panel-default > .panel-heading {
    /*background-color: #fafafa;*/
    border-bottom: none;
    color: #797979;
}

.panel-heading {
    border-radius: 0;
    border: none !important;
    padding: 10px 20px;
}

.panel-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
}

.panel-primary > .panel-heading {
    color: #fff;
    background-color: #0c63a9;
    border-color: #0c63a9;
}

/*重写Bootstrap list group 的样式*/
.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
    background-color: inherit;
    color: #e36209;
    border: 1px solid #ddd;
}

.form-control {
    border-radius: 0;
}

.btn {
    border-radius: 0;
}

.btn-primary {
    background-color: #5767d0;
}

.rich-editor {
    min-height: 500px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
}

.filter-index-image {
    display: inline-block;
    width: 24px;
    height: 28px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    top: 10px;
    left: 6px;
    margin-right: 5px;
}


table.compare-table tbody {
    display: block;

    overflow-y: scroll;
}

table.compare-table thead, table.compare-table tbody > tr, table.compare-table tfoot > tr {
    display: table;
    width: 100%;
    height: 40px;
    table-layout: fixed;

    text-align: center;
}

table.compare-table thead {
    width: calc(100% - 1em)
}

.smallTableRow {
    font-size: 12px;
}


.sort-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
}

.sort-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.background-sort-item {
    background-color: #baf0ff;
}

.drag-sort-item {
    background-color: #fff8ba;
}

.ant-btn-green {
    color: #fff;
    background-color: #25be52;
    border-color: #25be52;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
}

.ant-btn-green:hover,
.ant-btn-green:focus {
    color: #fff;
    background-color: #25cb53;
    border-color: #25cb53;
}

.ant-btn-blue {
    color: #fff;
    background-color: #1890ff;
    border-color: #25be52;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
}

.ant-btn-blue:hover,
.ant-btn-blue:focus {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
}
.ant-btn-orange {
    color: #fff;
    background-color: #ffa500;
    border-color: #ffa500;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
}

.ant-btn-orange:hover,
.ant-btn-orange:focus {
    color: #fff;
    background-color: #ffb801;
    border-color: #ffb801;
}

.ant-tag {
    margin: 0px 4px 3px 0px;
}

.ant-tag-blue {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
}

.my-tag {
    border-color: #1890ff;
    color: #1890ff;
    font-size: 12px;
    margin-bottom: 3px;
}

.my-tag.ant-tag-checkable-checked {
    color: #fff;
}

.calculator-show {
    top: 60px;
    right: 30px;
    opacity: .96;
    width: 50px;
    height: 60px;
    position: fixed;
    display: block;
    direction: ltr !important;

    z-index: 1001;
}

.calculator-show > a {
    position: relative;
    z-index: 2;
}

.calculator-show:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 17px;
    left: 25px;
    background: rgba(0, 0, 0, .38);
    width: 10px;
    height: 10px;
    box-shadow: 0 2px 18px 18px rgba(0, 0, 0, .38);
}

.img-button-toolbar {
    position: absolute;
    width: 115px;
    top: 10px;
    right: 20px;
    z-index: 99;
    text-align: center;
    padding: 5px;
    background: white;
}

.img-button {
    cursor: pointer;
    background: white;
    padding: 5px;
    border: 1px #c5c4c4 solid;
    border-radius: 2px;
}

.img-button.img-button-active {
    background: #eeeeee;
    border: 1px #b5b2b2 solid;
    border-radius: 2px;
}
.img-button-img {
    width: 22px;
    height: 22px;

}

.ant-table-middle .ant-table-thead > tr > th {
    background-color: rgb(0, 53, 148);
    color: #FFFFFF;
}

/*Ant Design 默认的按钮样式调整*/
.ant-btn-primary {
    background-color: rgb(0, 53, 148);
    border-color: rgb(0, 53, 148);
}

.ant-btn-primary:hover {
    background-color: rgb(0, 53, 148);
    border-color: rgb(0, 53, 148);
}

.ant-btn-danger {
    background-color: rgb(228, 0, 43);
    border-color: rgb(228, 0, 43);
}

.ant-btn-danger:hover {
    background-color: rgb(228, 0, 43);
    border-color: rgb(228, 0, 43);
}

/*自助*/

.span-underline{
    text-decoration: underline;
}
#classic-layout .sider-header {
    height: 100px;
    background: #003594;
    color: #fff;
}

#classic-layout .sider-header .logo {
    position: relative;
    transition: all .3s;
    overflow: hidden;
    alignment: center;
    vertical-align: middle;
    font-size: 24px;
    font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-align: center;
    line-height: 70px;
}

#classic-layout .sider-header .trigger {
    font-size: 18px;
    /*line-height: 30px;*/
    /*padding: 0 16px;*/
    cursor: pointer;
    transition: color .3s;
    text-align: right;
    padding-right: 10px;
}

#classic-layout .trigger:hover {
    color: #108ee9;
}

#classic-layout .ant-layout-sider-collapsed .logo {
    padding-left: 20px;
}

#classic-layout .logo img {
    display: inline-block;
    vertical-align: middle;

    height: 30px;
    max-width: 170px;
}

#classic-layout .ant-layout-sider {
    min-height: 100vh;
    box-shadow: 1px 0 2px 1px #B6B6B6;
    position: relative;
    z-index: 10;
    background: #FFFFFF;
}

#classic-layout .ant-layout-header {
    padding: 0 12px 0 0;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
    position: relative;
    line-height: inherit;
}

#classic-layout .ant-menu-horizontal {
    border-bottom: 0px;
    height: inherit;
    line-height: 100px;
}

#classic-layout .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: 0px;
}

#classic-layout .ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title {
    font-size: 14px;
}

#classic-layout .ant-menu-dark, .ant-menu-dark .ant-menu-sub {
    /*color: rgba(255, 255, 255, 0.65);*/
    background: #FFFFFF;
}

#classic-layout .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #000c17;
}

.ant-dropdown-menu-item {
    width: 160px;
}

/*#classic-layout .account-menu {*/
/*width: 160px;*/
/*}*/

#classic-layout .account {
    margin: 0 20px;
    background: rgba(255, 255, 255, .85);
    vertical-align: middle;
    height: 100px;
    padding: 20px 0;
}

#classic-layout .avatar {
    margin: 16px 8px 15px 0;
    color: #1890ff;
    background: rgba(255, 255, 255, .85);
    vertical-align: middle;
}

#classic-layout .footer {
    background-color: #FFFFFF;
}

#classic-layout .main-menu {
    height: 100px;
    display: flex;
    width: 100%
}

#classic-layout .account-menu .ant-menu {
    height: 100px;
}

#classic-layout .main-menu a {
    width: 120px;
    height: 100px;
    text-align: center;
    color: #000;
    opacity: 0.6;
    padding-top: 5px;
}

#classic-layout .main-menu .active {
    background: #003594;
    color: #fff;
    opacity: 1;
}

#classic-layout .main-menu a:hover {
    background: #003594;
    color: #fff;
    opacity: 1;
}

#classic-layout .ant-layout-footer {
    padding: 5px 50px;
}
.page-title{
    font-size: 15px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    /*padding-top: 10px;*/
    /*padding-bottom: 10px;*/
}
/*富文本框的图片尺寸调整框会被tabs挡住而显示不全，所以改变tabs的overflow样式为inherit，而不是原来的hidden*/
.ant-tabs{
    overflow: inherit;
}
.main-menu-inner {
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.sort-item-title {
    position: relative;
    /*display: block;*/
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    font-weight: 600;
    font-size: 12px;
    border: 1px solid rgba(0, 0, 0, .125);
}
.react-resizable {
    position: relative;
    border: solid 1px #000000;
}

.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
}

/** {*/
/*box-sizing: border-box;*/
/*}*/
/*html, body {*/
/*height: 100%;*/
/*}*/

/*body {*/
/*color: #222;*/
/*font-family: "Helvetica Neue", sans-serif;*/
/*font-weight: 200;*/
/*margin: 0 50px;*/
/*}*/

.react-draggable, .cursor {
    cursor: move;
}

.no-cursor {
    cursor: auto;
}

.cursor-y {
    cursor: ns-resize;
}

.cursor-x {
    cursor: ew-resize;
}

.react-draggable strong {
    background: #ddd;
    border: 1px solid #999;
    border-radius: 3px;
    display: block;
    margin-bottom: 10px;
    padding: 3px 5px;
    text-align: center;
}

.box {
    background: #fff;
    border: 1px solid #999;
    border-radius: 3px;
    width: 180px;
    height: 180px;
    margin: 10px;
    padding: 10px;
    float: left;
}

.no-pointer-events {
    pointer-events: none;
}

.hovered {
    background-color: gray;
}

/*
 * RemWrapper needs to take it's styles from this element,
 * and this element can't have an absolute position after it's kicked in.
 * AFAIK it's not possible to do this directly in the RemWrapper component.
 */
.rem-position-fix {
    position: static !important;
}

.ppt-page-content-container {

    width: 100%;
}

.ppt-page-content {
    background-repeat:no-repeat;
    width: 1124px;
    height: 792px;
    margin: 0 auto;
    background-size:cover;
 border:2px solid grey;
}

.ppt-page-list {
    /*position: fixed;*/
    overflow-y: auto;
    background-color: #e6e9ec;
    width: 255px;
    float: right
}

.ppt-page-list-item {
    padding: 5px;
    height: 140px;
    margin-bottom: 5px;
    width: 250px;
}

.ppt-page-tool-bar {
    font-size: 18px;
    font-weight: 600;
    /*position: fixed;*/
    overflow-y: auto;
    background-color: #e6e9ec;
    width: 80px;
    left: 5px;
    padding: 1px;
}

.ppt-page-tool-bar button {
    width: 78px;
    height: 75px;
    padding: 1px;
}
.select-box {
    border: 2px dotted #348fff;
    /*padding: 1px;  */
}
.content-item-show-penal{
     width:100% ;
     min-height:120px ;
     position: relative;
 }
.content-item-image{
    border:2px rgba(90, 156, 255, 0.45) solid;
    position: absolute;
}
.answer-rectangle-content{
    width:100% ;
    position: absolute;
    top:0;
    left: 0;
    /*height: 120px;*/
    /*background-color: rgba(145, 213, 255, 0.34);*/
}
.answer-rectangle{
    border:1px red dotted;
   /*background-color: red;*/
}
.answer-rectangle-select{
    background-color: rgba(145, 213, 255, 0.34);
    /*background-color: red;*/
}
.content-item-line{
    border-bottom: 1px grey solid;
}
.default{
    background-color: #F5F5F5;
    padding: 10px;
    margin-top: 10px;
}

.selected{
    color: #108ee9;
    background-color: #F5F5F5;
    padding: 10px;
    margin-top: 10px;
    font-weight: bold;
}
.paper-report-panel {
    max-width: 800px;
    overflow-y: auto;
    font-size: 16px;
}

.paper-report-panel .examinee-name {
    font-weight: 600;
    text-decoration: underline;

}

.report-paragraph {
    text-indent: 35px;
    line-height: 30px;
}


body {
    margin: 0;
    padding: 0;
    font-family: "Microsoft YaHei", "Arial", "\9ED1\4F53", "\5B8B\4F53", sans-serif;
    font-size: 14px;
    line-height: 1.5;
    background: #ededeb;
    -webkit-font-smoothing: antialiased;
    height: 100vh;
}

a:hover {
    text-decoration: none;
    cursor: pointer;
}

.page-content {
    background-color: #F5F5F5;
    padding: 15px;
    /*max-height: 0px;*/
    overflow-y: auto;
    flex: auto;
    box-sizing: border-box;
    display: block;
    height: 100%;
    width: 100%;
}

.spacing-h {
    margin-right: 10px;
}

.spacing-v {
    margin-top: 10px;
}

/*奇数行*/
.ant-table-tbody > tr:nth-of-type(odd) {
    background: #ffffff;
}

/*偶数行*/
.ant-table-tbody > tr:nth-of-type(even) {
    background: #f9f9f9;
}

/*Ant Design 默认的Form Item 底部间距太大*/
.ant-form-item {
    margin-bottom: auto;
}

/*清空padding的设置*/
.clear-padding {
    padding: 0px;
}

/*Ant Design的模态框padding默认24过大*/
.ant-modal-body {
    padding: 10px;
}

/*垂直居中*/
.vertical-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/*文字不换行，超出部分省略号, 需要为容器加上width限定，span无效*/
.overFlowEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.overFlowEllipsis:hover {
    overflow: visible;
    white-space: normal;
}

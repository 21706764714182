.main-menu-inner {
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.sort-item-title {
    position: relative;
    /*display: block;*/
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    font-weight: 600;
    font-size: 12px;
    border: 1px solid rgba(0, 0, 0, .125);
}
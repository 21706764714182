/*重写bootstrap的panel样式*/
.panel {
    border-radius: 0px;
    border: none;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.panel-default > .panel-heading {
    /*background-color: #fafafa;*/
    border-bottom: none;
    color: #797979;
}

.panel-heading {
    border-radius: 0;
    border: none !important;
    padding: 10px 20px;
}

.panel-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
}

.panel-primary > .panel-heading {
    color: #fff;
    background-color: #0c63a9;
    border-color: #0c63a9;
}

/*重写Bootstrap list group 的样式*/
.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
    background-color: inherit;
    color: #e36209;
    border: 1px solid #ddd;
}

.form-control {
    border-radius: 0;
}

.btn {
    border-radius: 0;
}

.btn-primary {
    background-color: #5767d0;
}

.rich-editor {
    min-height: 500px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
}

.filter-index-image {
    display: inline-block;
    width: 24px;
    height: 28px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    top: 10px;
    left: 6px;
    margin-right: 5px;
}


table.compare-table tbody {
    display: block;

    overflow-y: scroll;
}

table.compare-table thead, table.compare-table tbody > tr, table.compare-table tfoot > tr {
    display: table;
    width: 100%;
    height: 40px;
    table-layout: fixed;

    text-align: center;
}

table.compare-table thead {
    width: calc(100% - 1em)
}

.smallTableRow {
    font-size: 12px;
}


.sort-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
}

.sort-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.background-sort-item {
    background-color: #baf0ff;
}

.drag-sort-item {
    background-color: #fff8ba;
}

.ant-btn-green {
    color: #fff;
    background-color: #25be52;
    border-color: #25be52;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
}

.ant-btn-green:hover,
.ant-btn-green:focus {
    color: #fff;
    background-color: #25cb53;
    border-color: #25cb53;
}

.ant-btn-blue {
    color: #fff;
    background-color: #1890ff;
    border-color: #25be52;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
}

.ant-btn-blue:hover,
.ant-btn-blue:focus {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
}
.ant-btn-orange {
    color: #fff;
    background-color: #ffa500;
    border-color: #ffa500;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
}

.ant-btn-orange:hover,
.ant-btn-orange:focus {
    color: #fff;
    background-color: #ffb801;
    border-color: #ffb801;
}

.ant-tag {
    margin: 0px 4px 3px 0px;
}

.ant-tag-blue {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
}

.my-tag {
    border-color: #1890ff;
    color: #1890ff;
    font-size: 12px;
    margin-bottom: 3px;
}

.my-tag.ant-tag-checkable-checked {
    color: #fff;
}

.calculator-show {
    top: 60px;
    right: 30px;
    opacity: .96;
    width: 50px;
    height: 60px;
    position: fixed;
    display: block;
    direction: ltr !important;

    z-index: 1001;
}

.calculator-show > a {
    position: relative;
    z-index: 2;
}

.calculator-show:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 17px;
    left: 25px;
    background: rgba(0, 0, 0, .38);
    width: 10px;
    height: 10px;
    -webkit-box-shadow: 0 2px 18px 18px rgba(0, 0, 0, .38);
    -moz-box-shadow: 0 2px 18px 18px rgba(0, 0, 0, .38);
    box-shadow: 0 2px 18px 18px rgba(0, 0, 0, .38);
}

.img-button-toolbar {
    position: absolute;
    width: 115px;
    top: 10px;
    right: 20px;
    z-index: 99;
    text-align: center;
    padding: 5px;
    background: white;
}

.img-button {
    cursor: pointer;
    background: white;
    padding: 5px;
    border: 1px #c5c4c4 solid;
    border-radius: 2px;
}

.img-button.img-button-active {
    background: #eeeeee;
    border: 1px #b5b2b2 solid;
    border-radius: 2px;
}
.img-button-img {
    width: 22px;
    height: 22px;

}

.ant-table-middle .ant-table-thead > tr > th {
    background-color: rgb(0, 53, 148);
    color: #FFFFFF;
}

/*Ant Design 默认的按钮样式调整*/
.ant-btn-primary {
    background-color: rgb(0, 53, 148);
    border-color: rgb(0, 53, 148);
}

.ant-btn-primary:hover {
    background-color: rgb(0, 53, 148);
    border-color: rgb(0, 53, 148);
}

.ant-btn-danger {
    background-color: rgb(228, 0, 43);
    border-color: rgb(228, 0, 43);
}

.ant-btn-danger:hover {
    background-color: rgb(228, 0, 43);
    border-color: rgb(228, 0, 43);
}

/*自助*/

.span-underline{
    text-decoration: underline;
}
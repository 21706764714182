.paper-report-panel {
    max-width: 800px;
    overflow-y: auto;
    font-size: 16px;
}

.paper-report-panel .examinee-name {
    font-weight: 600;
    text-decoration: underline;

}

.report-paragraph {
    text-indent: 35px;
    line-height: 30px;
}


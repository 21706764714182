.default{
    background-color: #F5F5F5;
    padding: 10px;
    margin-top: 10px;
}

.selected{
    color: #108ee9;
    background-color: #F5F5F5;
    padding: 10px;
    margin-top: 10px;
    font-weight: bold;
}